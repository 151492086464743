var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"login-app"}},[_c('notifications',{staticStyle:{"margin-top":"70px","margin-right":"12px","z-index":"10000"},attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var item = ref.item;
var close = ref.close;
return [_c('v-alert',{attrs:{"dense":"","border":"right","colored-border":"","elevation":"2","min-width":"500","type":item.type,"dismissible":!item.timer},on:{"input":close,"click":function () {
          if (item.timer) { close(); }
        }}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',[_vm._v(_vm._s(item.text))])])]}}])}),_c('v-main',[_c('v-col',{staticClass:"align-self-center pa-3 text-right",staticStyle:{"position":"fixed","top":"0","right":"0"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","text":"","dark":"","data-cy":"lang_btn"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-web ")]),_vm._v(_vm._s(_vm.$i18n.locale == 'en' ? 'ENG' : '繁體')+" ")],1)]}}])},[_c('v-list',{attrs:{"data-cy":"lang_list"}},[_c('v-list-item',{attrs:{"data-cy":"eng_btn"},on:{"click":function($event){return _vm.loadLanguageAsync('en')}}},[_c('v-list-item-title',[_vm._v("ENG")])],1),_c('v-list-item',{attrs:{"data-cy":"chn_btn"},on:{"click":function($event){return _vm.loadLanguageAsync('zh-Hant')}}},[_c('v-list-item-title',[_vm._v("繁體")])],1)],1)],1)],1),_c('router-view')],1),_c('v-footer',{staticClass:"blue--text",attrs:{"id":"footer"}},[_vm._v(" "+_vm._s(_vm.$t('footer.copyright'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }