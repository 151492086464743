<template>
  <v-app id="login-app">
    <notifications
      width="auto"
      style="margin-top:70px; margin-right: 12px;z-index:10000;"
    >
      <template v-slot:body="{ item, close }">
        <v-alert
          dense
          border="right"
          colored-border
          elevation="2"
          min-width="500"
          :type="item.type"
          :dismissible="!item.timer"
          @input="close"
          @click="() => {
            if (item.timer) close();
          }"
        >
          <div class="title">
            {{ item.title }}
          </div>
          <div>{{ item.text }}</div>
        </v-alert>
      </template>
    </notifications>
    <v-main>
      <v-col
        class="align-self-center pa-3 text-right"
        style="position:fixed; top:0; right:0;"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              text
              dark
              data-cy="lang_btn"
            >
              <v-icon left>
                mdi-web
              </v-icon>{{ $i18n.locale == 'en' ? 'ENG' : '繁體' }}
            </v-btn>
          </template>
          <v-list
            data-cy="lang_list"
          >
            <v-list-item
              @click="loadLanguageAsync('en')"
              data-cy="eng_btn"
            >
              <v-list-item-title>ENG</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="loadLanguageAsync('zh-Hant')"
              data-cy="chn_btn"
            >
              <v-list-item-title>繁體</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <router-view />
    </v-main>
    <v-footer
      id="footer"
      class="blue--text"
    >
      {{ $t('footer.copyright') }}
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'
import Vue from 'vue'
// import Notification from '@/components/common/Notification'

Vue.use(Vuelidate)

export default {
  components: {
    // Notification
  },
  watch: {
    is_logged_in () {
      this.redirectIfNeeded()
    }
  },
  computed: {
    ...mapGetters('auth', [
      'is_logged_in'
    ]),
    ...mapState('auth', [
      'logging_in'
    ])
  },
  data: () => ({
    form: {
      organization: '',
      username: '',
      password: ''
    },
    show_pw: false,
    alert: false
  }),
  validations () {
    return {
      form: {
        organization: {
          required
        },
        username: {
          required
        },
        password: {
          required
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'login'
    ]),
    redirectIfNeeded () {
      if (this.is_logged_in && this.$route.name !== 'Dashboard') {
        this.$router.replace({ name: 'Dashboard' })
          .catch(err => {
            console.log(err)
          })
      }
    }
  },
  mounted () {
    this.redirectIfNeeded()
  }
}
</script>

<style>
.v-application {background-image: radial-gradient(circle at 69% 75%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 38%,transparent 38%, transparent 69%,transparent 69%, transparent 100%),radial-gradient(circle at 41% 58%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 3%,transparent 3%, transparent 75%,transparent 75%, transparent 100%),radial-gradient(circle at 94% 91%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 48%,transparent 48%, transparent 55%,transparent 55%, transparent 100%),radial-gradient(circle at 68% 38%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 34%,transparent 34%, transparent 36%,transparent 36%, transparent 100%),radial-gradient(circle at 81% 20%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 40%,transparent 40%, transparent 61%,transparent 61%, transparent 100%),radial-gradient(circle at 46% 37%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 37%,transparent 37%, transparent 76%,transparent 76%, transparent 100%),radial-gradient(circle at 49% 5%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 43%,transparent 43%, transparent 67%,transparent 67%, transparent 100%),radial-gradient(circle at 18% 58%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 4%,transparent 4%, transparent 20%,transparent 20%, transparent 100%),radial-gradient(circle at 43% 68%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 10%,transparent 10%, transparent 36%,transparent 36%, transparent 100%),linear-gradient(135deg, rgb(85, 133, 238),rgb(177, 145, 214));}
.v-footer {background-image: repeating-linear-gradient(45deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),repeating-linear-gradient(0deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),repeating-linear-gradient(135deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),repeating-linear-gradient(90deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),linear-gradient(90deg, rgb(17, 17, 17),rgb(66, 66, 66));}
</style>
